import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import '../styles/diwali-campaign.scss'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'

const NotFoundPage = () => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     const chat = document.getElementById("fc_frame");
  //     if (chat) {
  //       chat.style.display = "none";
  //     }
  //   }, 2000);

  //   return () => {
  //     const chat = document.getElementById("fc_frame");
  //     if (chat) {
  //       chat.style.display = "block";
  //     }
  //   };
  // }, []);

  return (
    <Layout>
      <SEO
        title="#ThisDiwaliMega"
        description="To win ₹50,000 share your Kissa"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
      />

      <div className="diwali">
        <div className="content">
          <div className="campaign-logo">
            <StaticImage
              loading="eager"
              src="../images/This-diwali-getmega-logo.png"
              alt="logo"
              placeholder="blurred"
              width={600}
            />
          </div>

          <h1 className="title">
            Terms and Conditions for{' '}
            <span className="red">#ThisDiwaliMega</span> Contest
          </h1>

          <div>
            <p>
              Yaad hai woh bachpan ki Diwali? Sunaao koi Diwali ka mazzedaar
              kissa, aur jeeto bada wala cash prize. You don&apos;t need to make
              a hi-fi video - just share your 25-30 sec kissa on your Instagram
              Reels or alternatively post your video on Facebook. Don’t forget
              to use #ThisDiwaliMega. Contest ends on 14th November, 11:59 pm. 3
              winners will be announced on November 15th. The funniest kissey
              will WIN! Oh, and likes & views count as well!
            </p>

            <p>1st prize: ₹50,000 | 2nd prize: ₹30,000 | 3rd prize: ₹20,000</p>

            <p>The final decision lies with the Mega Jury.</p>

            <p>Just follow these easy steps:</p>
            <ol>
              <li>
                <p>Follow Mega on Instagram / Facebook</p>
              </li>
              <li>
                <p>
                  Post your Diwali kissa on Instagram Reels / post your video on
                  Facebook
                </p>
              </li>
              <li>
                <p>
                  Use #ThisDiwaliMega and tag @Mega on Instagram / @getmegaapp
                  on Facebook
                </p>
              </li>
              <li>
                <p>Tag two of your friends</p>
              </li>
            </ol>

            <p>
              The following terms and conditions (“T&Cs”/ “Terms and
              Conditions”) shall be applicable to Megashots Internet Private
              Limited (“Mega”). ‘Mega’ contest to be conducted on its Instagram
              and Facebook pages; by Mega and shall be termed as follows:
            </p>

            <ul>
              <li>
                <p>“#ThisDiwaliMega” Contest on Instagram & Facebook.</p>
              </li>
            </ul>

            <p style={{ marginTop: '16px' }}>
              The above contest shall be referred to with its Contest title on
              the Instagram and Facebook pages which is owned by Mega.
            </p>

            <h2 style={{ marginTop: '30px' }}>Contest “Basic T&Cs”:</h2>
            <p>
              Following are the terms that shall be applicable to the Contest:
            </p>

            <ol>
              <li>
                The Contest is open only to Indian citizens, residing in India.
                Citizens and/or residents of countries other than India are not
                eligible to participate. Citizens and/or residents of countries
                other than India residing in India are not eligible to
                participate.
              </li>
              <li>
                The Participants must be adults having attained the age of
                eighteen (18) years to participate in the Contest as on the date
                of the Contest.
              </li>
              <li>
                (All persons fulfilling conditions mentioned in section 1 & 2
                shall be hereinafter referred to as “Participants”. By
                participating in the Contest, you (“Participant”) signify your
                assent to these Terms and Conditions stated below)
              </li>
              <li>
                Contest shall be open for participation as per the timelines
                specified in the T&Cs for Contest.
              </li>
              <li>
                Employees, agents and promoters (including their immediate
                family members) of Mega and any of their divisions, affiliates
                and subsidiaries, and others associated with the Contest in any
                manner, are not eligible to participate in the Contest.
              </li>
              <li>
                The Contest terms and conditions shall be mentioned on Mega
                website www.GetMega.com (“Website”). The Participants agree that
                by participating in the Contest, the Participants shall be bound
                by the terms and conditions provided at the Website.
              </li>
              <li>
                The Participants shall be required to provide basic personal
                information about them including but not limited to Participants
                name, address, mobile number, profile photo and email address,
                in order to register and participate in the Contest. The
                information provided by the Participants may be used by Mega for
                internal purposes and to send in the future to the Participants
                promotional information pertaining to the Mega network.
                Providing information to us is your choice. The Participant by
                providing the aforesaid sensitive personal information hereby
                agrees that Mega shall have the right to share the information
                so collected with such other third party as Mega is required for
                the purpose of the Contest and hereby agree that they shall not
                file any claim against Mega for sharing for such personal
                information. By participating in the Contest Participant hereby
                consents to receiving information about various marketing
                promotional activities/events of Mega including but not limited
                to receiving information/being contacted by the research team of
                Mega. However, by participating in the contest, you agree to
                allow us authorized use of your information and agree to be
                bound by the “Privacy Policy” available on this Website.
              </li>
              <li>
                The specific requirements for the Contest shall be outlined in
                the T&Cs of the contest.
              </li>
              <li>
                For the Contest, each Participant must submit their complete
                Entry/Entries (as defined here below) in order to qualify as an
                entrant in the Contest through Specific Mode of Participation
                specified in Specific T&C’s stated at end of this T&C.
              </li>
              <li>
                Participants entering the Contest may submit any number of
                Entries so long as such Entries meet Mega’s criteria as
                specified in these T&Cs even if not chosen as the final winners
                of the Contest and any Participant may at Mega’s sole discretion
                be declared a winner of the Contest.
              </li>
              <li>
                The manner, day and the basis of choosing the winner (“Winners”)
                shall be specified in the T&Cs of the Contest.
              </li>
              <li>
                The announcement about the Winners and the Prizes (defined
                below) shall be as per the T&Cs of the Contest.
              </li>
              <li>
                The distribution time and mode of distribution of the Prize
                shall be at the sole discretion of Mega.
              </li>
              <li>
                The Participant can win only once in this Contest. Any
                subsequent wins of the Participant (if declared) will be
                automatically void.
              </li>
              <li>
                Participants should ensure the accuracy of the information
                provided by them at the time of entering the Contest.
              </li>
              <li>
                Method and time of prize distribution: The Winners shall be
                declared on Mega Instagram handle at @GetMega and Facebook page
                (www.facebook.com/GetMegaApp). Winners can be asked to email
                their registered email address to the email address
                social@getmega.com. Mega may require Winners to provide valid
                photo identity documents including but not limited to passport,
                PAN Card and ration card prior to handing over the Prize.
              </li>
              <li>
                The Prizes, when the same are in form of some tangible goods or
                materials or vouchers, shall be sent by post and/or courier to
                the Winners as per the address specified by them at the time of
                registering for the Contest. Mega shall not be responsible if
                the Prize is damaged in the course of delivery through post
                and/or courier.
              </li>
              <li>
                Participants shall not post any profanity or abusive comments on
                Mega’s website Contest page/s. In the event of any such
                profanity or abusive comments posted on foregoing pages, such
                Participants shall be disqualified forthwith and Mega reserves
                its rights, in addition to removing such post, to take a legal
                action against such participant and/or user.
              </li>
              <li>
                Participants agree no clarifications on the questions and the
                answers used in the Contests shall be entertained by Mega.
              </li>
              <li>
                By participating in the Contest, each Participant agrees to
                follow these Terms and Conditions and is deemed bound by any
                decisions made by Mega in its sole discretion, including any
                interpretations of the Terms and Conditions.
              </li>
              <li>
                Mega including its divisions, affiliates and subsidiaries, and
                their respective agents and representatives, employees shall not
                be responsible for any loss of Entries for any reason whatsoever
                including, due to any technical problems for Entries received
                after the deadline as a consequence of computer service delays,
                interruptions, electronic failures or overloads, or for lost,
                misdirected or misplaced Entries. The Entries are void if
                unreadable, inaccurate, incomplete, damaged, tampered with,
                falsified, mechanically reproduced, not in prescribed format,
                irregular in any way or otherwise not in compliance with these
                Terms and Conditions.
              </li>
              <li>
                Mega reserves its right to select and declare or not to declare
                the Winner in the Contest.
              </li>
              <li>
                While accepting the Prize, the Winner may be required to sign an
                “indemnity and release of claims” form provided by Mega, as the
                case maybe, which shall release Mega of all liability. Failure
                to complete and sign any documents requested by Mega may result
                in disqualification and selection of an alternate Winner. All
                decisions of Mega are final in this regard.
              </li>
              <li>
                Mere participation/submitting Entries in the Contest does not
                entitle the Participants to win the Prize.
              </li>
              <li>
                The Prize will be awarded to the Winners pursuant to
                verification and in accordance with these Terms and Conditions.
              </li>
              <li>
                Mega may refuse to give the Prize(s) to Participant(s) in the
                event of his/her fraud, dishonesty or non-entitlement to
                participate in the Contest under these Terms and Conditions.
              </li>
              <li>
                The Prize is not transferable, assignable or exchangeable for
                cash equivalent. Only the prize Winners and no other person or
                agent, may claim the Prize.
              </li>
              <li>
                In case the Winner is / are found to be in violation of any and
                all rules of the Contest, Mega reserves the right to initiate
                legal proceedings against such person(s) including but not
                limited to having the concerned Winners to refund/replace the
                Prize(s) won.
              </li>
              <li>
                Mega has the right to substitute the Prize with other prizes of
                equal value, as determined by Mega in its sole discretion.
              </li>
              <li>
                Mega has the right to amend (add, delete or modify) the Terms
                and Conditions governing the Contest, prospectively or
                retrospectively, at its discretion and without prior notice.
              </li>
              <li>
                Mega reserves the right to disqualify any participant if it has
                reasonable grounds to believe that the Participant has breached
                any of these Terms and Conditions.
              </li>
              <li>
                Mega is not responsible for any errors or omissions in the terms
                and conditions contained herein. All information provided in the
                Contest is provided &quot;As is&quot; without warranty of any
                kind. Mega makes no representations and disclaims all express,
                implied, and statutory warranties of any kind to the Participant
                and/or any third party including, without limitation, warranties
                as to accuracy, timelines, completeness, merchantability or
                fitness for any particular purpose.
              </li>
              <li>
                Under no circumstance, unless due to willful misconduct or gross
                negligence, shall Mega and/or their directors, employees,
                officers, affiliates or subsidiaries, be liable to the
                Participant and/or any third party for any lost profits or lost
                opportunity, indirect, special, consequential, incidental, or
                punitive damages whatsoever. The Participant specifically agrees
                not to file in person/through any family member and/or any third
                party any applications, criminal and/or civil proceedings in any
                courts or forum in India against Mega their directors,
                employees, officers, affiliates or subsidiaries to claim any
                damages or relief in connection with the Contest.
              </li>
              <li>
                By entering into the Contest, the Winners/Participants waive all
                copyrights including but not limited to Intellectual Property
                Rights (IPR) and moral rights, rights of publicity and any
                related rights and consents to Mega’s right to use the
                picture(s) & video(s) uploaded by the Participant(s) the
                captioned posted for the Contest, picture, tape or portray
                him/her as a Contest Winners, and to exhibit this material in
                any and all media now existing or hereinafter created, including
                without limitation television, film, radio and print media,
                without any compensation whatsoever for advertising and
                publicity purposes, except where prohibited by law. The prize
                Winners also consent to Mega’s right to use his/her name, voice,
                video(s) or picture, or the content of his/her Contest Entry
                (collectively, the “Publicity Rights”). Mega can allow its
                agencies to exercise/exploit the Publicity Rights in conjunction
                with Mega. Mega shall also be free to use any ideas, concepts,
                know-how or techniques contained in the entries received for the
                Contest for any purpose.
              </li>
              <li>
                Mega does not make any commitment, express or implied, to
                respond to any feedback, suggestion and, or, queries of the
                Participants or furnish any reason or explanation for inclusion
                and, or, exclusion of any particular submission of the Entry of
                a Participant at any stage of the Contest.
              </li>
              <li>
                Under no circumstances shall Mega including their directors,
                employees, officers, affiliates or subsidiaries, be liable to
                the Participant and/or any third party for any lost profits or
                lost opportunity, indirect, special, consequential, incidental,
                or punitive damages whatsoever, even if Mega has been advised of
                the possibility of such damages. The Participant specifically
                agrees not to file in person/through any family member and/or
                any third party any Registrations, criminal and/or civil
                proceedings in any courts or forum in India against Mega their
                directors, employees, officers, affiliates or subsidiaries to
                claim any damages or relief in connection with the Contest.
              </li>
              <li>
                The Participant agrees that he/she shall not hold Mega and/or
                their employees, responsible for delays or any problem in
                connection to the Contest.
              </li>
              <li>
                Mega is in no manner whatsoever responsible and/or shall not be
                held liable in any manner whatsoever, for any injury, death,
                mental trauma caused to the Participants and/or the Participant
                in any manner whatsoever or for any reason whatsoever in
                connection to the Contest.
              </li>
              <li>
                The Participant shall do any and all acts and execute any and
                all documents in such manner and at such location as may be
                required by Mega in its sole and absolute discretion to protect,
                perfect or enforce any of the rights granted or confirmed to
                Mega herein.
              </li>
              <li>
                The content, including without limitation, the text, software,
                scripts, graphics, photos, sounds, music, videos, interactive
                features and the like (&quot;Content&quot;) and the trademarks,
                service marks and logos contained therein (&quot;Marks&quot;),
                all Entries, are owned by Mega. Content is provided to
                Participant AS IS for Participant’s information and personal use
                only and may not be used, copied, reproduced, distributed,
                transmitted, broadcast, displayed, sold, licensed, or otherwise
                exploited for any other purposes whatsoever without the prior
                written consent of the respective owners. Participant agrees not
                to engage in the use, copying, or distribution of any of the
                Content other than expressly permitted herein, including any
                use, copying, or distribution of user submissions of third
                parties for any purposes whatsoever.
              </li>
              <li>
                Mega does not make any commitment, express or implied, to
                respond to any feedback, suggestion and, or, queries of the
                Participants or furnish any reason or explanation for inclusion
                and, or, exclusion of any particular submission of the Entry of
                a Participant at any stage of the Contest.
              </li>
              <li>
                Mega is not responsible for any errors or omissions in the terms
                and conditions contained herein. All information provided in the
                Contest is provided &quot;As is&quot; without warranty of any
                kind. Mega makes no representations and disclaims all express,
                implied, and statutory warranties of any kind to the Participant
                (s) and/or any third party including, without limitation,
                warranties as to accuracy, timelines, completeness,
                merchantability or fitness for any particular purpose
              </li>
              <li>
                Under no circumstance shall Mega including their directors,
                employees, officers, affiliates or subsidiaries, be liable to
                the Participant and/or any third party for any lost profits or
                lost opportunity, indirect, special, consequential, incidental,
                or punitive damages whatsoever, even if Mega has been advised of
                the possibility of such damages.
              </li>
              <li>
                Non-conformance by any Participant and /or Winners of the
                Contest will result in immediate disqualification of the
                Participant from participation or winning the Prize as the case
                maybe.
              </li>
              <li>
                Mega reserves the right to withdraw or discontinue and/or
                terminate the Contest at any stage without prior notice and
                without any liability whatsoever to the Participants.
              </li>
              <li>
                The decisions of Mega and its representatives shall be final and
                binding on all aspects of the Contest.
              </li>
              <li>
                Your participation in the Contest by posting the Entries will be
                deemed as an acceptance of the above stated Terms and
                Conditions.
              </li>
              <li>
                Participants are providing their personal information to
                Megashots Internet Pvt Ltd. and such information will only be
                used for Mega’s internal purposes and to send Participants
                promotional information in the future pertaining to the
                Mega/Website.
              </li>
              <li>
                All taxes, levies and duties due and owing under applicable and
                statutory laws in connection with all Prizes if any, and all
                other costs, including insurance, incidental costs, gifts,
                gratuities and taxes, are the sole responsibility of the
                Prize(s) Winners.
              </li>
              <li>
                Mega shall not be responsible for any lost, late or misdirected
                computer transmission or network, electronic failures of any
                kind or any failure to receive entries owing to transmission
                failures or due to any technical reasons
              </li>
              <li>
                The Participants by providing the aforesaid sensitive personal
                information hereby agree that Mega shall have the right to share
                the information so collected with such other third party as
                required by Mega for the purpose of the Contest and hereby
                agreed that they shall not file any claim against Mega for
                sharing for such personal information. Please refer to Mega’s
                Privacy Policy that governs the use of your personal
                information.
              </li>
              <li>
                In exercise of the powers conferred by clause (of) of
                sub-section (2) of section 87 read with section 43A of the
                Information Technology Act, 2000 (21 of 2000), the Central
                Government has framed the Information Technology (reasonable
                security practices and procedures and sensitive personal data or
                information) Rules, 2011(“Privacy Rules”) which makes it
                mandatory for any concern to deal with any personal information
                including sensitive personal information as per the said Privacy
                Rules. In course of providing the aforesaid Entries in the
                Contest, Mega shall treat the information, videos and pictures
                as sensitive and confidential and as per the aforementioned
                Privacy Rules such information has to be now dealt with as per
                the provisions specified in the said Rules.
              </li>
              <li>
                Mega shall deal with any Data/information including sensitive
                personal information, if any, that it receives from Mega of any
                contests it runs or otherwise collects, holds, uses and
                processes in the following manner: a) Mega may also disclose
                Data/information including sensitive personal information, if
                any, to governmental agencies and regulators (e.g., tax
                authorities), social organizations (e.g., the social security
                administration), human resources benefits providers (e.g.,
                health insurers), external advisors (e.g., lawyers, accountants,
                and auditors), courts and other tribunals, and government
                authorities, to the extent required or permitted by applicable
                legal obligations upon prior written approval from Mega. b).The
                level of data protection using technical, managerial,
                operational and physical security control measures by Mega shall
                be such as to be in compliance with all applicable laws
                including but not limited to Information Technology Act, 2000
                and Privacy Rules. c).The term ‘Data/information’ including
                sensitive personal information, if any, shall mean and include
                the name and contact information (home address, telephone and
                fax numbers and email address), valid identity proof such as
                passport number, driver’s license number, permanent account
                number (PAN), or other details pertaining to the identity of the
                Participants.
              </li>
              <li>
                Participants confirm that the pictures and videos uploaded by
                them or any part thereof do not infringe upon the statutory
                rights, common law rights, intellectual property rights
                including copyrights in literary, dramatic, musical or motion
                picture rights, patent rights or the trademark or trade names of
                any person, firm, corporation, association or entity whatsoever.
                Neither the Pictures / Videos nor any part thereof violate the
                statutory rights of, or the right of privacy, or constitute a
                libel or slander against any person, firm, corporation,
                association or entity whatsoever, or violate any other rights
                not herein specifically enumerated. No pictures / videos or any
                part thereof shall be defamatory or contribute contempt of court
                or breach of contract, or breach of any provision of statute,
                nor hurt the sentiments of religious groups. In the event of any
                infringement and/or any unlawful usage of any information
                including pictures provided by the Participants, Mega shall not
                be held liable on any account whatsoever.
              </li>
              <li>
                Mega may now or in the future permit the submission of the
                Entry(ies) submitted by Participant as a part of Entry, and the
                hosting, sharing, and/or publishing of such Entry. Participant
                understands that whether or not such Entry(ies) are published,
                Mega does not guarantee any confidentiality with respect to any
                submissions.
              </li>
              <li>
                Once uploaded and published, Participants are not allowed to
                request for removal, editing or altering the Entry in any manner
                whatsoever.
              </li>
              <li>
                The Contest is void where prohibited by law. All relevant
                national and local laws apply.
              </li>
              <li>
                The Contest shall be governed by and construed in accordance
                with the applicable laws in India. All matters with respect to
                the Contests are subject to the jurisdiction of the Courts at
                Bangalore only.
              </li>
              <li>
                For any queries on the Contest and/or the Terms and Conditions,
                please send a direct message (DM) to the Mega Instagram handle
                @Mega or the Mega Facebook page www.facebook.com/GetMegaApp
              </li>
            </ol>

            <h2 className="table-title">
              <span className="red">#ThisDiwaliMega</span> Contest on Instagram,
              Facebook – Specific T&Cs
            </h2>

            <table>
              <tbody>
                <tr>
                  <td>Dates of the Contest</td>
                  <td>
                    The activity shall be carried out from 7th November 2020 to
                    14th November 2020 11:59 pm
                  </td>
                </tr>
                <tr>
                  <td>Platforms on which Contest shall be available</td>
                  <td>
                    Platform available for this Activity shall be the Instagram
                    and Facebook pages of Mega as mentioned hereinabove.
                  </td>
                </tr>
                <tr>
                  <td>Specific Terms of Participation</td>
                  <td>
                    <p>
                      Participants will be required to share their videos on
                      Instagram & Facebook with the Hashtag #ThisDiwaliMega
                    </p>
                    <p>
                      The thread shall be with regard to #ThisDiwaliMega Note:
                      While submitting Entries, Participants shall ensure that
                      the Entries:-
                    </p>
                    <ul>
                      <li>
                        shall not violate the rights of any third party,
                        including, but not limited to, privacy rights,
                        copyrights, trademark rights and/or any other
                        intellectual property rights;
                      </li>
                      <li>
                        is not abusive, harmful, harassing, blasphemous,
                        defamatory, obscene, libellous, invasive of
                        another&apos;s privacy, hateful, or racially, ethnically
                        objectionable, disparaging, relating or encouraging
                        money laundering or gambling, or otherwise unlawful in
                        any manner whatever;
                      </li>
                      <li>does not harm minors in any way;</li>
                      <li>
                        does not violate any law for the time being in force;
                      </li>
                      <li>
                        does not deceive or mislead about the origin of such
                        messages or communicates any information which is
                        grossly offensive or menacing in nature;
                      </li>
                      <li>
                        does not contain software viruses or any other computer
                        code, files or programs designed to interrupt, destroy
                        or limit the functionality of any computer resource;
                      </li>
                      <li>
                        shall not create any liability for Mega or any of its
                        respective parents, subsidiaries, affiliates,
                        successors, assigns, employees, agents, directors,
                        officers and shareholders;
                      </li>
                    </ul>

                    <p>
                      Mega will collate all the Entries and combine them into
                      one video which Mega shall have the right to exhibit on
                      any and all platforms through any and all mediums
                      throughout the world. Participants agree and acknowledge
                      that by participating in this Contest, they hereby assign
                      all rights arising out of the videos to Mega and they
                      shall have no claims whatsoever on the usage of such
                      videos by Mega.
                    </p>
                    <p>
                      Please note that Mega has the right to edit and select the
                      Entries sent by the Participants. Participants acknowledge
                      that Mega shall select such Entries as per its sole
                      discretion and Participants shall have no claims to that
                      effect.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>Specific Process for choosing the Winners</td>
                  <td>
                    <ul>
                      <li>
                        All the videos received with the hashtag #ThisDiwaliMega
                        on Instagram and Facebook will be collected by Mega.
                      </li>
                      <li>
                        Mega will choose winners (one or more) collectively from
                        all the Entries submitted on Mega’s Facebook & Instagram
                        pages at its sole discretion. All rights in selecting
                        the winners vest solely and exclusively with Mega.
                      </li>
                      <li>
                        The decision of Mega in choosing the Winner shall be
                        final and binding on the Participants and the
                        Participants while entering into this Contest agree to
                        accept Mega’s decision as final without any further
                        claim in this regard.
                      </li>
                      <li>
                        Mega shall not entertain any mail or query questioning
                        or otherwise asking for the definitive process for
                        choosing the Winner and the Participants by
                        participating in the Contest shall be bound by these
                        T&Cs and Basic T&Cs.
                      </li>
                      <li>
                        Mega reserves the right to withdraw or discontinue
                        and/or terminate the Contest at any stage without prior
                        notice and without any liability whatsoever to the
                        Participants.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Announcement of the Winners</td>
                  <td>
                    <ul>
                      <li>
                        Mega shall declare only such number of winners from its
                        Facebook and Instagram page, as it may solely decide
                      </li>
                      <li>
                        The Winners shall be declared by Mega on its Facebook
                        Page and Instagram Profile within 30 days from the
                        conclusion of the Contest at its sole discretion.
                      </li>
                      <li>
                        The Winners shall be declared as per the terms of the
                        Basic T&Cs
                      </li>
                      <li>
                        Mega shall contact the Winners through a direct message
                        through Facebook / Instagram once or through an email
                        once. The Winners need to revert with the required
                        details and formalities within Seventy Two (72) hours
                        after contacting them. Any responses received after this
                        deadline shall be considered forfeited.
                      </li>
                      <li>
                        Mega may choose not to announce any winner at their sole
                        discretion.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Prize(s)</td>
                  <td>
                    <ul>
                      <li>
                        The name of the winner selected by Mega will be
                        announced on its Instagram and Facebook pages.
                      </li>
                      <li>
                        3 winners may get cash prizes of ₹50,000, ₹30,000 &
                        ₹20,000 based on the sole discretion of Mega. The
                        Prize(s) shall be sent by email / courier to the Winners
                        as per the address specified by them during the Contest.
                        Mega shall not be responsible if any Prize(s) is damaged
                        in the course of delivery through post and/or courier.
                      </li>
                      <li>
                        All taxes, levies and duties due and owing under
                        applicable and statutory laws in connection with all
                        Prize(s) if any, and all other costs, including
                        insurance, incidental costs, gifts, gratuities and
                        taxes, will be borne by the Winner/s.
                      </li>
                      <li>
                        The Winners shall be contacted in the manner specified
                        in the Basic T&Cs
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
